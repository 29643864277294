"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _jquery = _interopRequireDefault(require("jquery"));

(0, _jquery.default)(function () {
  var _context;

  (0, _jquery.default)('#depth1').html((0, _find.default)(_context = (0, _jquery.default)('#depth1').parent().parent()).call(_context, '.link a.on').html());
  (0, _jquery.default)('.depth2').html((0, _jquery.default)('#contents h3').html());
});